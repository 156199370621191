<template>
	<PaddingContainer
		v-if="globalSettings.enableCondos"
		id="condominios"
		class="condos-listing-section"
		:class="{
			'condos-section-padding': !padding
		}"
		:padding="padding"
		component="section"
	>
		<div v-if="title || description" class="condos-listing-heading">
			<span v-if="title" class="condo-listing-title heading-font">
				{{ fixEscapedLineBreaks(title) }}
			</span>

			<div v-if="description" class="condo-listing-description default-font">
				<ParagraphsWithLineBreak :text="description" />
			</div>

			<span v-if="showSeparator" class="separator"></span>
		</div>

		<div v-if="variant === 'default'" class="cards-section">
			<div v-if="condos.length" class="card-grid">
				<CondoCard v-for="condo in condos" :key="condo.id" :condo="condo" />
			</div>

			<ErrorMessage
				v-else-if="!shouldShowLoading"
				title="Nenhum condomínio encontrado"
				:description="errorMessage"
			/>

			<LoadingIcon v-show="shouldShowLoading" class="loading" />

			<ButtonBlock
				v-show="!isInfiniteScrollEnabled && hasMoreCondosToShow"
				class="show-more-condos-button"
				text="VER MAIS CONDOMÍNIOS"
				type="button"
				size="medium"
				:is-outlined="false"
				@click.prevent="enableInfiniteScroll"
			/>

			<div
				v-show="isInfiniteScrollEnabled"
				ref="scrollSentinel"
				class="scroll-sentinel"
			></div>
		</div>
	</PaddingContainer>
</template>

<script setup lang="ts">
import {
	useIntersectionObserver,
	watchDebounced,
	whenever
} from '@vueuse/core';

import type { CondoListingSection } from '@SHARED/core/entities/sections/CondoListingSection';
import type { GlobalWebsiteSettings } from '@SHARED/core/entities/WebsiteConfig';
import type { Condo } from '@SHARED/core/entities/Condo';
import type { CssSize } from '@SHARED/utils/helperTypes';
import type { CondoFilters } from '@SHARED/core/entities/Condo/filters';

import { fixEscapedLineBreaks, isEmpty } from '@SHARED/utils';

import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import ParagraphsWithLineBreak from '@SHARED/components/atoms/ParagraphsWithLineBreak.vue';
import ErrorMessage from '@SHARED/components/molecules/ErrorMessage.vue';

import CondoCard from '@/components/molecules/CondoCard.vue';

import LoadingIcon from '~icons/mdi/loading';

defineOptions({ name: 'CondoListingSection' });

type CondoListingSectionProps = CondoListingSection['config'] & {
	isLoading?: boolean;
	condos?: Condo[] | null;
	isInfiniteScrollEnabledByDefault?: boolean;
	condoFilters?: Partial<CondoFilters>;
};

const props = withDefaults(defineProps<CondoListingSectionProps>(), {
	variant: 'default',
	cardVariant: 'default',
	perPage: 8,
	isLoading: false,
	condos: null,
	isInfiniteScrollEnabledByDefault: false,
	title: '',
	description: '',
	showSeparator: false,
	itemGap: '1.5rem',
	condoFilters: () => ({})
});

const domain = useState<string>('domain');

const globalSettings = useState<GlobalWebsiteSettings>('globalSettings');

const condosRepository = inject(CONDOS_REPOSITORY)!;

const page = ref<number>(1);

const isScrollSentinelVisible = ref<boolean>(false);

const isInfiniteScrollEnabled = ref<boolean>(
	props.isInfiniteScrollEnabledByDefault
);

const totalCondosCount = ref<number | null>(null);

const totalFilteredCondos = ref<number | null>(null);

const hasAppliedNewFilters = ref<boolean>(false);

const hasMoreCondosToShow = computed<boolean>(() => {
	if (totalCondosCount.value === null) return true;

	return condos.value.length < (totalFilteredCondos.value || 0);
});

const condosOffset = computed<number>(() => props.perPage * (page.value - 1));

const condoGridItemGap = computed<CssSize>(() => props.itemGap || '1.5rem');

const scrollSentinel = ref<HTMLElement | null>(null);

const shouldShowLoading = computed<boolean>(
	() =>
		(isFetchingCondos.value || props.isLoading) &&
		totalCondosCount.value !== null
);

const {
	data: condos,
	pending: isFetchingCondos,
	execute: fetchCondosAsyncData
} = useAsyncData<Condo[]>(
	`${domain.value}:condo-listing-section`,
	async () => {
		if (props.condos) return props.condos;

		const fetchedData = await fetchCondos();

		return fetchedData.condos;
	},
	{
		default: () => []
	}
);

const errorMessage = ref<string | null>(null);

async function fetchCondos(): Promise<{
	condos: Condo[];
	totalCount: number;
	filteredCount: number;
}> {
	if (!hasMoreCondosToShow.value && !hasAppliedNewFilters.value) {
		return {
			condos: condos.value,
			totalCount: totalCondosCount.value || 0,
			filteredCount: 0
		};
	}

	const [condosResult, condosRequestError] =
		await condosRepository.getCondosFromCompany({
			domain: domain.value,
			maxQuantity: props.perPage,
			filters: props.condoFilters,
			offset: condosOffset.value
		});

	if (condosRequestError || !condosResult) {
		const notFoundError = {
			response: {
				status: 404
			}
		};

		const error =
			(condosRequestError?.originalErrorObject as any) || notFoundError;

		// <!-- TODO: improve error handling -->
		// eslint-disable-next-line no-console
		console.error(condosRequestError);

		errorMessage.value =
			error?.response.status === 404
				? 'No momento, não temos nenhum condomínio disponível.'
				: 'Ocorreu um erro ao carregar os condomínios.';

		return {
			condos: [],
			totalCount: 0,
			filteredCount: 0
		};
	}

	if (isScrollSentinelVisible.value && hasMoreCondosToShow.value) {
		page.value++;
	}

	totalCondosCount.value = condosResult.totalCount;
	totalFilteredCondos.value = condosResult.filteredCount;
	hasAppliedNewFilters.value = false;

	if (!isEmpty(props.condoFilters) && condosResult.filteredCount === 0) {
		errorMessage.value = 'Nenhum condomínio encontrado com essa busca.';
	}

	return condosResult;
}

function enableInfiniteScroll() {
	isInfiniteScrollEnabled.value = true;
}

const {
	pause: pauseInfiniteScroll,
	resume: resumeInfiniteScroll,
	isActive: isInfiniteScrollActive
} = useIntersectionObserver(
	scrollSentinel,
	([{ isIntersecting }]) => {
		if (!isInfiniteScrollEnabled.value || !hasMoreCondosToShow.value) {
			return;
		}

		isScrollSentinelVisible.value = isIntersecting;
	},
	{
		threshold: 1
	}
);

watch(isScrollSentinelVisible, newValue => {
	if (!newValue || !hasMoreCondosToShow.value || isFetchingCondos.value) {
		return;
	}

	page.value++;
});

whenever(page, async () => {
	if (page.value === 1) return;

	try {
		isFetchingCondos.value = true;

		const { condos: fetchedCondos } = await fetchCondos();

		condos.value = condos.value.concat(fetchedCondos);
	} catch {
		// TODO: tratamento de erro...
	} finally {
		isFetchingCondos.value = false;
	}
});

watchDebounced(
	() => props.condoFilters,
	async () => {
		page.value = 1;

		hasAppliedNewFilters.value = true;

		await fetchCondosAsyncData();
	},
	{ debounce: 500 }
);

watch(hasAppliedNewFilters, newValue => {
	if (newValue && isInfiniteScrollActive.value) {
		pauseInfiniteScroll();
		return;
	}

	resumeInfiniteScroll();
});
</script>

<style lang="scss" scoped>
.condos-listing-heading {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 1.75rem;
	padding-bottom: 1.75rem;
	gap: 1rem;

	.condo-listing-page-subheading,
	.condo-listing-title {
		color: var(--black);
		text-align: center;
	}

	.condo-listing-title {
		font-size: 2.375rem;
	}

	.condo-listing-description {
		font-size: 1.25rem;
	}

	.separator {
		margin-top: 0.75rem;
	}
}

.condos-section-padding {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.condos-listing-section {
	background-color: var(--white);
	display: flex;
	flex-direction: column;
	width: 100%;

	.cards-section {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 3rem;
		position: relative;

		.card-grid {
			display: grid;
			grid-template-columns: repeat(1, minmax(0, 1fr));
			gap: v-bind(condoGridItemGap);

			@include screen-up(md) {
				grid-template-columns: repeat(2, minmax(0, 1fr));
			}
		}
	}
}

.show-more-condos-button {
	align-self: center;
}

.loading {
	align-self: center;
	width: 3.5rem;
	height: 3.5rem;
}
</style>
