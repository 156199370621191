<template>
	<NuxtLink
		class="condo-card default-font"
		:to="{
			name: 'CondoPage',
			params: {
				condoSlug: condo.slug
			}
		}"
	>
		<div class="card-thumbnail">
			<NuxtImg
				:src="thumbnail.url"
				:alt="thumbnail.caption || 'Imagem do condomínio'"
				width="100%"
				sizes="300px md:400px lg:500px"
				:placeholder="DEFAULT_NUXT_IMAGE_PLACEHOLDER"
				loading="lazy"
			/>
		</div>

		<div class="card-content">
			<div class="card-heading">
				<span class="default-font condo-name">
					{{ condo.name }}
				</span>
			</div>
			<div class="card-information">
				<span v-if="numberOfPropertiesText" class="default-font condo-info">
					{{ numberOfPropertiesText }}
				</span>
				<span class="default-font condo-info">
					{{ condoAddressText }}
				</span>
			</div>
		</div>
	</NuxtLink>
</template>

<script setup lang="ts">
import type { CondoCard } from '@SHARED/core/entities/sections/CondoListingSection';
import type { CondoImage } from '@SHARED/core/entities/Condo';

import { CondoPresenter } from '@SHARED/presenters/CondoPresenter';
import { DEFAULT_NUXT_IMAGE_PLACEHOLDER } from '@SHARED/utils/style';
import { grammarInflection } from '@SHARED/utils';

defineOptions({ name: 'CondoCard' });

const props = withDefaults(defineProps<CondoCard>(), {
	variant: 'default'
});

const thumbnail = computed<CondoImage>(() =>
	CondoPresenter.getThumbnail(props.condo)
);

const numberOfPropertiesText = computed<string | null>(() => {
	if (!props.condo.numberOfProperties) return null;

	const infoText = grammarInflection(
		props.condo.numberOfProperties,
		'imóvel',
		'imóveis'
	);

	return `${props.condo.numberOfProperties} ${infoText}`;
});

const condoAddressText = computed<string>(() =>
	CondoPresenter.getAddressText(props.condo)
);
</script>

<style lang="scss" scoped>
.condo-card {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
	background-color: var(--offwhite);
	transition: all 200ms ease-in-out;

	&:hover {
		.card-thumbnail {
			img {
				transform: scale(1.1);
			}

			.campaign-modal-button {
				opacity: 1;
			}
		}
	}

	.card-thumbnail,
	.card-content {
		position: relative;
	}

	.card-thumbnail {
		display: flex;
		flex-direction: column;

		img {
			width: 100%;
			object-fit: cover;
			height: 17rem;
			transition: all 200ms ease-in-out;
		}
	}

	.card-content {
		padding: 1.25rem 1.5rem;
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 0.75rem;
		background-color: var(--offwhite);

		.card-heading .condo-name {
			font-size: 1.5rem;
			line-height: 100%;
			font-weight: 500;
			color: var(--black);
		}

		.card-information {
			display: flex;
			gap: 0.5rem;
			.condo-info {
				color: var(--black);
				font-size: 1rem;
				line-height: 100%;
				font-weight: 400;
				display: flex;
				align-items: center;

				&:not(:first-child)::before {
					content: '';
					display: inline-block;
					margin-right: 0.5rem;
					width: 4px;
					height: 4px;
					background-color: var(--gray);
				}
			}
		}
	}
}
</style>
